








.clrvk8o{--height:var(--control-height, 36px);height:var(--height);position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:8px;-webkit-box-flex-wrap:nowrap;-webkit-flex-wrap:nowrap;-ms-flex-wrap:nowrap;flex-wrap:nowrap;width:100%;}.clrvk8o:has(textarea){height:auto;}.clrvk8o .s1t8sfo3{width:1.25rem;height:1.25rem;}.clrvk8o .i19riw65{pointer-events:none;}.clrvk8o .i19riw65.irz1j0z{pointer-events:unset;}.clrvk8o[disabled] .i19riw65{color:#A6A7AB;}.clrvk8o.sfdndg0 .i19riw65{color:#21ac21;}.clrvk8o.w7vkxsl .i19riw65{color:#FACA2C;}.clrvk8o.e1pq4dzo .i19riw65{color:#e54747;}.clrvk8o.g3c8xpd{gap:0;}.clrvk8o.g3c8xpd *:focus{outline:none;}.clrvk8o.g3c8xpd>*{height:var(--height);margin-right:0;box-shadow:none;}.clrvk8o.g3c8xpd>*:not(:first-child){border-top-left-radius:0;border-bottom-left-radius:0;}.clrvk8o.g3c8xpd>*:not(:first-child).clrvk8o>*{border-top-left-radius:0;border-bottom-left-radius:0;}.clrvk8o.g3c8xpd>*:not(:last-child){border-right-width:0;border-top-right-radius:0;border-bottom-right-radius:0;}.clrvk8o.g3c8xpd>*:not(:last-child).clrvk8o>*{border-right-width:0;border-top-right-radius:0;border-bottom-right-radius:0;}.clrvk8o:has(.i19riw65)>.i19riw65{position:absolute;width:calc(var(--height) - 2px);height:calc(var(--height) - 2px);background:transparent;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center;z-index:1;background:transparent!important;}.clrvk8o:has(.i19riw65)>.i19riw65:last-of-type{right:1px;top:1px;}.clrvk8o:has(.i19riw65)>.i19riw65:first-child{left:1px;top:1px;right:initial;}.clrvk8o:has(.i19riw65):has(.i19riw65:last-child) .i13bbq6i{padding-right:var(--height);}.clrvk8o:has(.i19riw65):has(.i19riw65:first-child) .i13bbq6i{padding-left:var(--height);}
