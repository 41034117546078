








.ip3froj:hover{background:#DAF2FC;}.ip3froj.hi38ymw{background:#DAF2FC;}.ip3froj.stj0ed8{background:#DAF2FC;color:#43CCF8;}

.sg529me{width:100%;cursor:pointer;}.sg529me .c18g8zlu{width:100%;}.sg529me .t1jflu8u{width:100%;cursor:pointer;}.sg529me .t1jflu8u.d1w65v8y{cursor:not-allowed;}.sg529me.o9tyy6k .ijp2fj8{rotate:90deg;}.sg529me .d6q1on5{overflow:hidden;white-space:nowrap;display:block;text-overflow:ellipsis;}.sg529me .c1yxepun{max-height:215px;}.sg529me .ijp2fj8{color:#757577;-webkit-transition:-webkit-transform 0.125s ease-in;transition:transform 0.125s ease-in;pointer-events:none;font-size:12px;rotate:-90deg;-webkit-transition:rotate 0.125s ease-in;transition:rotate 0.125s ease-in;}.sg529me .ijp2fj8[disabled]{color:#A6A7AB;}
